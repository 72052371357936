<template>
  <div class="divs" >
      <div v-html="listpar.content"></div>
  </div>
</template>

<script>
import apiurl from "@/api/apiurl/apiurl.js"
export default {
  name: "newsinvite",
  data() {
    return {
      listpar:'',
    };
  },

  created() {
   this.getSong()
  },

  methods: {
    //查询接口
    async getSong() {
      let res = await apiurl.PublicAPP({
        bizCatalog: "AgreementEntity",
        handleMode: "fetch",
        bizId:this.$route.query.id
      });
      if (this.$common.verifyAjaxResult(res, false) == false) return;
      this.listpar = res.Result;
    },
  },
};
</script>

<style scoped>
.divs {
  text-align: left;
  width:90%;
  margin: 0 auto;
}
</style>
